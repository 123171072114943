<template>
  <section class="sideone position-relative">
    <v-row style="align-items: center" justify="space-between">
      <v-col cols="12" md="6" class="px-0">
        <v-img :src="icon" class="pa-3 bg-sec bg-secimgin" style="height: 75vh">
          <!-- <img :src="logo" alt="" /> -->
          <div
            class="sec-login"
            style="
              width: 400px;
              margin: auto;
              height: 50%;
              display: grid;
              place-items: center start;
            "
          >
            <div style="width: 100%">
              <h3 class="mb-3">
                {{ $t("Register_with_us") }}
              </h3>
              <p class="mb-3">{{ $t("dataentry") }}</p>
              <v-text-field
                type="text"
                variant="solo"
                :placeholder="$t('name')"
                class="mb-2"
                v-model="loginForm.name"
              ></v-text-field>
              <v-text-field
                type="text"
                variant="solo"
                :placeholder="$t('phone_number')"
                class="mb-5 tel"
                v-model="loginForm.phone"
                hide-details
              ></v-text-field>
              <v-text-field
                type="text"
                variant="solo"
                :placeholder="$t('District')"
                class="mb-5 tel"
                v-model="loginForm.district"
                hide-details
              ></v-text-field>

              <div class="text-red mb-4">
                {{ errorMessage ? errorMessage : "" }}
              </div>
              <!-- <v-btn v-bind="props" text="Open Dialog"> </v-btn> -->
              <v-btn
                width="100%"
                height="50px"
                class="text-white"
                color="#BB86FC"
                style="box-shadow: rgb(77 71 195 / 20%) 0px 2px 16px 0px"
                @click="handleLogin"
              >
                {{ $t("Register_with_us") }}
              </v-btn>
              <!-- <textotp/> -->
              <v-dialog width="500" v-model="openotp">
                <v-card
                  class="py-11 px-8 text-center ma-0 over"
                  elevation="12"
                  max-width="500"
                  width="100%"
                  color="purple-lighten-2"
                >
                  <v-sheet color="purple-lighten-2">
                    <h2>
                      {{ $t("Added_successfully") }}
                    </h2>
                    <!-- <v-otp-input
                          v-model="otp"
                          length="4"
                          type="tel"
                          variant="solo"
                          inputmode="number"
    
                        ></v-otp-input> -->
                  </v-sheet>
                </v-card>
              </v-dialog>
            </div>
          </div>
          <div class="w-100 d-flex justify-end arrowimg mt-12">
            <img :src="arrow" alt="" />
          </div>
        </v-img>
      </v-col>
      <div
        class="position-absolute logimg"
        style="right: 40%; top: 25px; width: 280px; z-index: 111"
      >
        <v-img :src="Vect">
          <div
            class="d-flex justify-center align-center w-100 h-100"
            style="font-size: 28px; font-weight: 500"
          >
            <p class="text-center">{{ $t("Register_with_us") }}</p>
          </div>
        </v-img>
        <!-- <img :src="Vect"  alt="" /> -->
      </div>

      <v-col cols="12" md="5" class="px-0">
        <v-img :src="Loginamico" class="imgsection"> </v-img>
      </v-col>
    </v-row>
  </section>
</template>
<script>
import logo from "../assets/image/logo.png";
import image from "../assets/image/Vector.png";
import Loginamico from "../assets/image/Loginamico.svg";
import Vect from "../assets/image/Vect.png";
import arrow from "../assets/image/Grouparrow.png";
// import textotp from "./textotp.vue";
import CrudDataService from "@/Service/CrudDataService";
export default {
  data() {
    return {
      otp: "",
      logo: logo,
      icon: image,
      Loginamico: Loginamico,
      Vect: Vect,
      arrow: arrow,
      loginForm: {
        phone: "",
        name: "",
        district: "",
      },
      invalidPhoneNumber: false,
      validationMessage: "",
      errorMessage: "",
      openotp: false,
      otpInputs: ["", "", "", ""], // Array to hold the values of each input
    };
  },
  components: {},
  methods: {
    async handleLogin() {
      await CrudDataService.create(`join-us`, this.loginForm)
        .then((response) => {
          this.openotp = true;
          setTimeout(() => {
            this.$router.push({ name: "home" });
          }, 2000);
          this.errorMessage = "";
        })
        .catch((error) => {
          this.errorMessage = error.data.message;
        });
    },
  },
  mounted() {},
};
</script>
<style >
.bg-secimgin .v-img__img--contain {
    object-fit: fill;
}

.sideone .v-input--density-default .v-field--variant-outlined,
.sideone .v-input--density-default .v-field--single-line,
.sideone .v-input--density-default .v-field--no-label {
  background: #f0efff;
  color: #a7a3ff;
}
@media (max-width: 998.98px) {
  .sideone .imgsection {
    display: none;
  }
  .sideone .logimg {
    display: none;
  }
}
@media (max-width: 768.98px) {
  .sideone .logimg {
    display: none;
  }
  .sideone .arrowimg {
    display: none !important;
  }
}
@media (max-width: 560px) {
  .sideone .sec-login {
    width: 90% !important;
  }

  .sideone .bg-secimgin {
    height: 68vh !important;
    /* object-fit: cover !important; */
  }
  .bg-secimgin .v-img__img--contain {
    object-fit: fill !important;
  }

}
@media (max-width: 440.98px) {
  .sideone .bg-secimgin {
    height: 55vh !important;
    /* object-fit: cover !important; */
  }
}
@media (max-width: 389.98px) {
  .sideone .bg-secimgin {
    height: 65vh !important;
    /* object-fit: cover !important; */
  }
}
.v-overlay__scrim {
  background: rgba(187, 134, 252, 0.762);
}
</style>
