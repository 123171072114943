<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <section>

    <!-- main navbar -->
    <v-row justify="end" align="center">
      <v-col cols="12" md="2" sm="3" class="py-sm-4 pb-0 pt-4 text-center">
        <img :src="logo" alt=""  @click="redirectto('/')" width="90" height="45" v-if="logo" style="object-fit: contain;"
/>
      </v-col>
      <v-col cols="12" sm="6" class="py-sm-4 py-0 ">
        <div v-if="$route.path == '/cart'||$route.path == '/cart/lastorder'||$route.path == '/complete-order'"></div>
        <div v-else>
          <div class="position-relative">
            <div class="d-flex mx-auto align-center paddinginput">
            <v-text-field
    v-model="searchQuery"
    @keyup.enter="performSearch"
    density="compact"
    variant="solo"
    :label="$t('what')"
    single-line
    hide-details
    rounded="0"
    style="border: 1px solid #bb86fc"
  ></v-text-field>
  <v-btn @click="performSearch"   style="background-color: #bb86fc"
            class="rounded-0 search text-white">{{ $t('Search') }}</v-btn>
           
          </div>
          <div class="searchdiv" v-if="show">
             <div class="d-flex py-2 border-b" v-for="oneitem in resultsearch" :key="oneitem.id" >
              <img :src="oneitem.cover_image" width="120" height="120" class="rounded"/>
              <h4 class="pr-2" @click="detail(oneitem.id)" style="cursor: pointer">
                {{  oneitem.name  }}
              </h4>
             </div>
            </div>
          </div>
       
    
          <!-- <v-text-field
            density="compact"
            variant="solo"
            label=" ماذا تريد"
            single-line
            hide-details
            rounded="0"
            style="border: 1px solid #bb86fc"
          >
          </v-text-field>
          <v-btn
          
          >
            بحث
          </v-btn> -->
        </div>
      </v-col>
      <v-col cols="12" sm="3" class="py-sm-4 py-0 pb-3 mt-md-0 mt-3">
        <div class="d-flex mx-auto" style="justify-content: center">
          <div
            class="d-flex flex-column align-center px-2 iconspan"
            @click="cart"
          >
            <v-icon color="#8B96A5">mdi-cart </v-icon>

            <span>{{ $t('cart') }}</span>
          </div>

          <div
            class="d-flex flex-column align-center px-2 iconspan"
            @click="favourite"
          >
            <v-icon color="#8B96A5">mdi-heart</v-icon>

            <span>{{ $t('favourite')}}</span>
          </div>
       
          <div class="d-flex flex-column align-center px-2 iconspan" @click="redirectto('/profile')" v-if="isUserLoggedIn">
            <v-icon color="#8B96A5">mdi-account</v-icon>
            <span>{{ $t('Profile') }}</span>
          </div>
          <div class="d-flex flex-column align-center px-2 iconspan" @click="account" v-else>
            <v-icon color="#8B96A5">mdi-account</v-icon>
            <span>
              {{ $t('registration') }} 
            </span>
          </div>
         
        </div>
      </v-col>
    </v-row>
    <hr />
    <!-- second navbar -->
    <div v-if="$route.path == '/cart'||$route.path == '/cart/lastorder'||$route.path == '/complete-order'"></div>

    <v-row justify="end" align="center" class="py-3" v-else>
      <v-col cols="12" xl="5" lg="7" md="9" sm="10">
        <v-btn
              v-for="(item, index) in items"
              :key="index"
              @click="redirectto(item.route)"
              :ripple="false"
              variant="text"
              class="mx-md-2 mx-0 px-1 links "
              rounded="xl"
              :class="{ 'active': $route.path === item.route }"
            >
              {{ $t(item.text) }}
            </v-btn>
     

     
        <v-btn :ripple="false" variant="text" class="mx-md-2 mx-0 links" rounded="xl" @click="toggleLanguage">
          {{$t('Language')}}
        </v-btn>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import { useAuth } from "@/http-common";
import image from "../../../src/assets/image/logo.png";
import { ref } from 'vue';
import axios from "axios";
import CrudDataService from "@/Service/CrudDataService";
export default {
  data: () => ({
    logo: '',
    searchQuery: '',
    dropdownOpen: false,
      newLocale: "ar",
      dataFromLocalStorage:'',
      resultsearch:[],
      show:false,
      items: [
      { text: "Home", route: "/" },
      { text: "Categories", route: "/categories" },
      { text: "Best_offers", route: "/offers" },
      { text: "products", route: "/products" },
      { text: "About", route: "/about-us"},
      { text: "Contact", route: "/contact-us" },
      
    ],
    currentActiveRoute: '/',

  }),
  setup() {
    const { isLoggedIn } = useAuth();
    const isUserLoggedIn = ref(isLoggedIn());
    return {
      isUserLoggedIn,
    };
  },
  methods: {
    account() {
      this.$router.push({ name: "Login" });
    },
  async performSearch() {
      // Logic to perform the search
      console.log('Searching for:', this.searchQuery);
      // Add your API call or search logic here
      let res = await CrudDataService.getAll(`products?filter[name]=${this.searchQuery}`);
      console.log(res.data.data.data);
      if (res.data.data.data.length>0) {
      this.resultsearch=res.data.data.data
        this.show=true;
      }else{
        this.show=false
      }
    },
    detail(id) {
      this.$router.push({ name: "Detail", params: { id } });
      this.show=false;
      setTimeout(() => {
            window.location.reload();
          },1000 );
         

    },
    async logoo() {
      let res = await CrudDataService.getAll("settings");
      res.data.data.forEach((Element) => {
        if (Element.key === "logo") {
          console.log(Element);
          console.log(Element.key);
          this.logo=Element.image
         
        }}
      )
    },
    redirectto(route) {
      this.$router.push(route)
    this.currentActiveRoute = route;
    // Additional redirection logic here
  },
  isActive(route) {
    return this.currentActiveRoute === route;
  },
    product() {
      this.$router.push({ name: "Product" });
    },
    favourite() {
      this.$router.push({ name: "Favourite" });
    },
    cart() {
      this.$router.push({ name: "Cart" });
    },
    openDropdown() {
      this.dropdownOpen = true;
    },
    closeDropdown() {
      this.dropdownOpen = false;
    },
    toggleLanguage() {
      this.newLocale = this.$i18n.locale === "en" ? "ar" : "en";
      this.$i18n.locale = this.newLocale;
      document.documentElement.setAttribute(
        "dir",
        this.newLocale === "ar" ? "rtl" : "ltr"
      );
      localStorage.setItem("lang", this.newLocale);

      axios.defaults.headers.common["Accept-Language"] = this.newLocale;
     
      setTimeout(() => {
        window.location.reload();
      }, 300);
    },

  },
  mounted(){
    this.logoo()
  }
};
</script>
<style scoped>
.searchdiv{
  position: absolute;
    border: 1px solid #bb86fc;
    width: 100%;
    border-radius: 6px;
    padding: 10px;
    background: #fff;
    z-index: 111111111;
    height: 250px;
    overflow-y: scroll;
}
.iconspan {
  cursor: pointer;
}
.iconspan span {
  color: #8b96a5 !important ;
}

input {
  padding: 0 !important;
}
.search {
  width: 100px;
  padding-top: 0;
  height: 39.7px;
  box-shadow: none;
}
.links.active {
  color: #bb86fc;
}
</style>
<style>
.paddinginput .v-text-field input.v-field__input {
  padding-top: 1px !important;
  padding-bottom: 0 !important;
}
.paddinginput .v-text-field .v-field {
  box-shadow: none;
}
.links.v-btn:focus-visible::after,
.links.v-btn:focus-visible > .v-btn__overlay,
.links.v-btn:hover > .v-btn__overlay {
  opacity: 0;
}
</style>
