<template>
  <section>
    <div
      style="
        position: fixed;
        right: 10%;
        bottom: 10px;
        z-index: 555;
        cursor: pointer;
      "
    >
      <a
        :href="`https://wa.me/966552565269`"
        class="text-black iconwhatsapp"
        target="_blank"
      >
        <v-icon icon="mdi-whatsapp" class="mx-1 text-white" size="28"></v-icon
      ></a>
    </div>
  </section>
</template>
<script>
import CrudDataService from "@/Service/CrudDataService";

export default {
  data() {
    return {
      whatsapp_link: "",
    };
  },
  methods: {
    async footer() {
      let res = await CrudDataService.getAll("settings");
      console.log(res.data.data);
      res.data.data.forEach((Element) => {
        if (Element.key === "whatsapp_link") {
          this.whatsapp_link = Element.value;
          console.log(this.whatsapp_link);
        }
      });
  
    },
  },
  mounted() {
    this.footer();
  },
};
</script>
<style>
.iconwhatsapp {
  background: #03dac5;

  border-radius: 50%;
  width: 47px;
  height: 47px;
  display: grid;
  text-align: center;
  place-items: center;
  text-decoration: none;
  margin-right: -50px;
  margin-bottom: 35px;
}

@media (max-width: 569.98px) {
  .iconwhatsapp {
    margin-right: -20px;
  }
}
</style>
