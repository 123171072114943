<template>
  <v-card class="mx-auto my-3 pa-md-10 pa-3" v-if="product">
    <v-row>
      <v-col cols="12" md="4" >
        <v-carousel v-model="activeSlide" hide-delimiters class="carou_product">
  <v-carousel-item
  v-for="image in product.images"
    :src="image.path"
    :key="image.id"
    cover
  

  ></v-carousel-item>
  </v-carousel>
        <!-- <v-img cover height="100%" :src="image" class="rounded"></v-img> -->
      </v-col>
      <v-col cols="12" md="8">
        <v-col cols="12" lg="8" sm="12">
          <v-card-item class="px-sm-12 px-4 pt-0">
            <h4>{{ product.name }}</h4>
          </v-card-item>
          <v-card-text class="px-sm-10 px-4">
            <v-row align="center" class="mx-0">
              <v-rating
                :model-value="product.rating"
                color="amber"
                density="compact"
                half-increments
                readonly
                size="small"
              ></v-rating>

              <div class="ms-4" style="color: #ff9017" v-if="product.rating">
                {{ product.rating }}
              </div>
              <v-icon
                icon="mdi-circle-medium"
                color="#DEE2E7"
                class="d-sm-inline d-none"
                v-if="product.total_orders_quantity"
              ></v-icon>

              <div
                style="color: #8b96a5"
                class=""
                v-if="product.total_orders_quantity"
              >
                <v-icon icon="mdi-basket-outline"></v-icon>
                {{ product.total_orders_quantity }}
              </div>
            </v-row>
            <v-row
              align="center"
              justify="space-between"
              class="mx-0 pt-6 pb-2"
              style="width: 350px"
            >
              <div class="titleh2">{{ $t("price") }}</div>
              <v-card-text  class="d-flex" v-if="product.discounted_price" >
                  <p v-if="product.base_price" class="text-decoration-line-through px-1 text-red ">
                  {{
                  Math.trunc(product.base_price)
                }}
                {{ $t('rs') }}
                </p>
                <p >
                  {{
                  Math.trunc(product.discounted_price)
                }}
                {{ $t('rs') }}
                </p>
               </v-card-text>
               <v-card-text  class="" v-else>
                  <p >
                  {{
                  Math.trunc(product.base_price)
                }}
                </p>
                
               </v-card-text>
            </v-row>
          </v-card-text>
          <v-divider class="mx-4 mb-1"></v-divider>
          <v-card-text class="px-sm-10 px-3">
            <v-row
              align="center"
              justify="space-between"
              class="mx-0 pb-2"
              style="width: 350px"
            >
              <div class="titleh2">{{ $t("Type") }}</div>
              <div v-for="categorie in product.categories" :key="categorie.id">
                {{ categorie.name }}
              </div>
            </v-row>
          </v-card-text>
          <v-divider class="mx-4 mb-1"></v-divider>
          <v-card-text class="px-sm-10 px-4">
            <div class="titleh2 pb-3">
              {{ $t("Product_information") }}
            </div>
            <p v-html="product.description"></p>
          </v-card-text>
        </v-col>
        <v-card-actions
          style="justify-content: end; align-items: end"
          class="flex-column"
        >
          <v-btn
            style="background-color: #bb86fc; color: white"
            class="mb-3"
            width="165"
            variant="text"
            @click="addcart(product.id)"
          >
            {{ $t("Add_cart") }}
            <v-icon icon="mdi-basket-outline" class="ms-2"></v-icon>
          </v-btn>

          <v-btn
            style="border: 1px solid#BB86FC; color: #bb86fc"
            width="165"
            variant="text"
            @click="toggleHeart(product)"
          >
            {{ $t("Save_later") }}
            <v-icon :icon="getIcon(product)" class="ms-2"></v-icon>
          </v-btn>
        </v-card-actions>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="showSnackbar"
      color="purple-lighten-2
"
    >
      {{ $t("Added_successfully") }}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" v-bind="attrs" @click="showSnackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
  <section class="position-relative" style="height: 100vh;display: grid;
    place-items: center;"
    v-else
   >

<section class="cate">
</section>
      <!-- <v-progress-circular
      indeterminate
      color="#d6487c8a"
    ></v-progress-circular> -->
    <img :src="info" width="250">

    </section>
 
</template>
<script>
import CrudDataService from "@/Service/CrudDataService";
import info from "../../assets/image/Into.gif"

export default {
  data() {
    return {
info,

    activeSlide: 0, 
      product: "",
      image: "",
      showSnackbar: false,
    };
  },
  methods: {
    async addcart(id) {
      let res = await CrudDataService.create(`cart`, {
        product_id: id,
        quantity: 1,
      });
      if (res.data.success) {
        this.showSnackbar = true;
      }
    },
    async singlrproduct() {
      let res = await CrudDataService.get(
        "products",
        `${this.$route.params.id}`
      );
      this.product = res.data.data;
      this.image = res.data.data.cover_image;
    },
    async toggleHeart(product) {
      let res = await CrudDataService.create(
        `products/${product.id}/favorites`,
        ""
      ).then(() => {
        product.isfavourite = !product.isfavourite;
      });
    },
    getIcon(product) {
      return product.isfavourite ? "mdi-heart" : "mdi-heart-outline";
    },
  },
  mounted() {
    this.singlrproduct();
  },
};
</script>
<style>
.carou_product .v-btn--icon.v-btn--density-default {
    transform: rotateY(180deg) !important;
}
[dir="ltr"] .carou_product .v-btn--icon.v-btn--density-default {
  transform: none !important;
}
.titleh2 {
  color: #8b96a5;
}
</style>
