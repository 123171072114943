<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h1 class="text-center font-weight-medium">{{ $t("Addresses") }}</h1>
      </v-col>
    </v-row>
    <section class="position-relative" style="height: 100vh;display: grid;
    place-items: center;"
    v-if="loading"
   >

<section class="cate">
</section>
    <img :src="info" width="250">

    </section>
    
   <section v-else>
    <section v-if="items.length>0">
        <v-row class="prof">
      <v-col cols="12" class="pa-0" v-for="item in items" :key="item.id">
        <div
          class="d-flex align-center justify-space-between pa-5 cursor"
          @click="gotoaddress(item.id)"
        >
          <span class="my-4 text-h5 font-weight-light">{{ item.title }} </span>
          <v-icon size="34">mdi-chevron-left</v-icon>
        </div>
        <v-divider class="mx-4"></v-divider>
      </v-col>
    </v-row>
    </section>
    <section v-else>
      <v-card class="hint my-7 py-2" elevation="0">
        <v-card-text>
            {{ $t("nothing") }}
            {{ $t("address") }}
          
        </v-card-text>
      </v-card>
    </section>
   </section>
 
  </v-container>
</template>
<script>
import info from "../../assets/image/Into.gif"
import CrudDataService from "@/Service/CrudDataService";
export default {
  data() {
    return {
        info,
    loading:false,
      items: [],
    };
  },
  methods: {
    gotoaddress(id) {
      this.$router.push({ name: "addressedit", params: { id } });
    },
    navigateTo(path) {
      this.$router.push(path);
    },
 
    async alladdress() {
      this.loading = true; // Start loading
      try {
        let res = await CrudDataService.getAll("user/addresses");
      this.items = res.data.data;
 
}catch (error) {
console.error("Failed to fetch data:", error);
// Handle error
} finally {
this.loading = false; // End loading regardless of success or failure
}
     
    },
   
  },
  mounted() {
    this.alladdress();
  },
};
</script>
<style scope>
.cursor {
  cursor: pointer;
  color: #455a64;
}
.hint {
  background: #03dac5;
  color: white;
  text-align: center;
}
.hint .v-card-text {
  font-size: 18px;
}
</style>