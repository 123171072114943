<template>
  <v-container>
    <v-breadcrumbs :items="items">
      <template v-slot:divider>
        <v-icon icon="mdi-chevron-left"></v-icon>
      </template>
    </v-breadcrumbs>
    <v-row justify="center">
      <v-col cols="12" sm="8">
        <v-card class="pa-4" outlined>
          <v-row>
            <v-col cols="12" class="d-flex justify-center">
              <v-avatar size="120" class="position-relative appear">
                <v-file-input
                  @change="onFileSelected"
                  accept=".pdf, image/jpeg, image/png"
                  prepend-icon="mdi-camera"
                  class="position-absolute custom"
                  hide-details
                  style="z-index: 22"
                ></v-file-input>

                <img :src="imageUrl" alt="صورة" class="w-100" />
              </v-avatar>
              <!-- <v-icon class="ml-2">mdi-pencil</v-icon> -->
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="d-flex justify-center mt-2"> </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <label for=""> {{ $t('First_Name') }}  </label>
              <v-text-field
                type="text"
                density="compact"
                variant="solo"
                single-line
                hide-details
                rounded="0"
                class="border mt-3 contact"
                v-model="formData.fname"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <label for="">  {{ $t('latest_Name') }} </label>
              <v-text-field
                type="text"
                density="compact"
                variant="solo"
                single-line
                hide-details
                rounded="0"
                class="border mt-3 contact"
                v-model="formData.lname"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <label for=""> {{ $t('Country') }} </label>
              <multiselect
                :options="optioncountries"
                :searchable="true"
                label="name"
                class="selectinput mt-2"
                v-model="formData.country_id"
                @input="region($event)"
              ></multiselect>
            </v-col>
            <v-col cols="12" md="6">
              <label for=""> {{ $t('Region') }} </label>
              <multiselect
                :options="optionregion"
                :searchable="true"
                label="name"
                class="selectinput mt-2"
                v-model="formData.region_id"
                @input="city($event)"
              ></multiselect>
            </v-col>
            <v-col cols="12" md="6">
              <label for=""> {{ $t('City') }} </label>
              <multiselect
                :options="optioncities"
                :searchable="true"
                label="name"
                class="selectinput mt-2"
                v-model="formData.city_id"
                @input="districts($event)"
              ></multiselect>
            </v-col>
            <v-col cols="12" md="6">
              <label for=""> {{ $t('District') }} </label>
              <multiselect
                :options="optiondistricts"
                :searchable="true"
                label="name"
                class="selectinput mt-2"
                v-model="formData.district_id"
              ></multiselect>
            </v-col>
            <v-col cols="12" md="12">
              <label for=""> {{ $t('Type') }} </label>
              <multiselect
                :options="gender"
                :searchable="true"
                label="name"
                class="selectinput mt-2"
                v-model="formData.gender"
              ></multiselect>
            </v-col>
            <v-col cols="12" md="12">
              <label for="">{{ $t('email') }} </label>
              <v-text-field
                type="text"
                density="compact"
                variant="solo"
                single-line
                hide-details
                rounded="0"
                class="border mt-3 contact"
                v-model="formData.email"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="12">
              <label for=""> {{ $t('phone_number') }}</label>
              <v-text-field
                type="text"
                density="compact"
                variant="solo"
                single-line
                hide-details
                rounded="0"
                class="border mt-3 contact"
                v-model="formData.phone"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="12">
              <label for=""> {{ $t('date_birth') }}  </label>
              <v-text-field
                type="date"
                density="compact"
                variant="solo"
                single-line
                hide-details
                rounded="0"
                class="border mt-3 contact"
                v-model="formData.birth_date"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <div class="text-center">
            <v-btn
              class="rounded mt-5"
              style="background-color: #bb86fc; color: white"
              width="200"
              height="45"
              variant="text"
              @click="updateProfile"
            >
              {{ $t('Edit_profile') }}  
            </v-btn>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="showSnackbar"
      color="purple-lighten-2
"
    >
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" v-bind="attrs" @click="showSnackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>
<script>
import Multiselect from "@vueform/multiselect";
import avatar from "../../assets/image/av.webp";
import CrudDataService from "../../Service/CrudDataService";
export default {
  components: {
    Multiselect,
  },
  data: () => ({
    items: [
      {
        title: "الرئيسية",
        disabled: false,
        href: "/",
      },
      {
        title: "الملف الشخصي",
        disabled: false,
        href: "/update-profile",
      },
    ],
    showSnackbar: false,
    text:'',
    formData: {
      fname: "",
      lname: "",
      phone: "",
      email: "",
      birth_date: "",
      gender: "",
      country_id: "",
      region_id: "",
      city_id: "",
      district_id: "",
    },
    optioncountries: [],
    regionarray: [],
    optionregion: [],
    optioncities: [],
    optiondistricts: [],
    countries: [],
    districtarray: [],
    gender: [
      {
        value: "male",
        name: "ذكر",
      },
      {
        value: "female",
        name: "انثي",
      },
    ],
    selectedFile: null,
    imageUrl: avatar,
  }),
  methods: {
    onFileSelected(event) {
      this.formData.image = event.target.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        this.imageUrl = reader.result;
      };
      reader.readAsDataURL(this.formData.image);
    },
    async getcontry() {
      let res = await CrudDataService.getAll("countries");
      this.countries = res.data.data;
      this.optioncountries = res.data.data.map((state) => ({
        value: state.id,
        name: state.name,
      }));
      if (this.countries) {
        this.countries.forEach((element) => {
        if (element.id === this.formData.country_id) {
          this.regionarray = element.regions;
          this.optionregion = this.regionarray.map((state) => ({
            value: state.id,
            name: state.name,
          }));
        }
      });
      }
    if (this.regionarray) {
      this.regionarray.forEach((element) => {
        if (element.id === this.formData.region_id) {
          this.cities = element.cities;
          this.optioncities = this.cities.map((state) => ({
            value: state.id,
            name: state.name,
          }));
        }
      });
    }
   if ( this.cities) {
    this.cities.forEach((element) => {
        if (element.id === this.formData.city_id) {
          this.districtarray = element.districts;
          this.optiondistricts = this.districtarray.map((state) => ({
            value: state.id,
            name: state.name,
          }));
        }
      });
   }
   
    },
    region(e) {
      this.countries.forEach((element) => {
        if (element.id === e) {
          this.regionarray = element.regions;
          this.optionregion = this.regionarray.map((state) => ({
            value: state.id,
            name: state.name,
          }));
        }
      });
    },
    city(e) {
      this.regionarray.forEach((element) => {
        if (element.id === e) {
          this.cities = element.cities;
          this.optioncities = this.cities.map((state) => ({
            value: state.id,
            name: state.name,
          }));
        }
      });
    },
    districts(e) {
      this.cities.forEach((element) => {
        if (element.id === e) {
          this.districtarray = element.districts;
          this.optiondistricts = this.districtarray.map((state) => ({
            value: state.id,
            name: state.name,
          }));
        }
      });
    },
    async getProfile() {
      await CrudDataService.getAll("user/profile")
        .then((response) => {
          this.formData.fname = response.data.data.fname;
          this.formData.lname = response.data.data.lname;
          this.formData.email = response.data.data.email;
          this.formData.gender = response.data.data.gender;
          this.formData.phone = response.data.data.phone;
          this.formData.birth_date = response.data.data.birth_date;
          this.formData.country_id = response.data.data.country.id;
          this.formData.region_id = response.data.data.region.id;
          this.formData.city_id = response.data.data.city.id;
          this.formData.district_id = response.data.data.district.id;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async updateProfile() {
      await CrudDataService.create("user/profile", this.formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }).then((response)=>{
        this.showSnackbar=true;
        this.text=response.data.message
      })
    },
  },
  mounted() {
    this.getcontry();
    this.getProfile();
  },
};
</script>
<style>
.custom {
  visibility: hidden;
}
.appear:hover img {
  filter: brightness(0.8);
}
.appear:hover .custom {
  visibility: visible;
}
.selectinput .multiselect {
  padding: 10px;
  border: none !important;
  height: 100%;
}

.multiselect-multiple-label,
.selectinput .multiselect .multiselect-placeholder,
.multiselect-single-label {
  padding-right: 9px !important;
  right: 0 !important;
  left: auto !important;
}
.multiselect-wrapper {
  padding-left: 9px !important;
}
</style>
<style>
.sideone .v-input--density-default.custom .v-field--variant-outlined,
.v-input--density-default.custom .v-field--single-line,
.v-input--density-default.custom .v-field--no-label {
  display: none !important;
}
.custom.v-input--horizontal .v-input__prepend {
  margin-inline-end: 0;
}
</style>
