<template>
  <v-app>
    <div v-if="$route.path == '/login'" style="background: #eeeeee">
<Login/>
    </div>
    <div v-else-if="$route.path == '/delete-account'" style="background: #eeeeee">
    
<DeleteAccount/>
    </div>
    <div v-else-if="$route.path == '/form'" style="background: #eeeeee">
      <Navbar />
    <Registarform/>
    <WhatsApp/>
    <Footer/>
    </div>
    <div v-else-if="$route.path == '/signup'" style="background: #eeeeee">
<SignUp/>
</div>
<div v-else>
  <Navbar />
    <v-main style="background: #eeeeee">
      <router-view />
    </v-main>
    <Subscrib/>
      <Footer/>
      <WhatsApp/>
  </div>
   
  </v-app>
</template>

<script>
import Navbar from "./components/HOME/Navbar.vue";
import Subscrib from "./components/HOME/Subscrib.vue";
import Footer from "./components/HOME/Footer.vue";
import Login from "./views/Login.vue";
import DeleteAccount from "./views/DeleteAccount.vue";
import Registarform from "./views/Registarform.vue";
import SignUp from "./views/SignUp.vue";
import WhatsApp from './components/WhatsApp/WhatsApp.vue';

export default {
  name: "App",
  data: () => ({
  }),
  components: {
    Navbar,
    Subscrib,
    Footer,
    Login,
    SignUp,
    WhatsApp,
    DeleteAccount,
    Registarform
  },
  mounted() {
  },
};
</script>
<style>
.v-locale--is-ltr {
  direction: rtl !important;
}
</style>
