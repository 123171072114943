<template>
  <section class="mt-8 package">
    <div class="d-flex justify-space-between mb-5">
      <h2>{{ $t('products') }}</h2>
    </div>
    <v-window
      v-model="window"
      class="elevation-0 position-relative"
      :show-arrows="false"
    >
      <v-window-item v-for="(group, i) in groupedProducts" :key="i">
        <v-row>
          <v-col
            cols="6"
            md="3"
            v-for="(product, index) in group"
            :key="index"
            class="h-100"
            data-aos="zoom-in-left" data-aos-duration="800"
            :data-aos-delay="150 * index"
          >
            <v-card
              class=""
              flat
              style="height: 360px"
              @click="detail(product.id)"
            >
            <img :src="product.cover_image"
                cover
                height="200"
                class="grey lighten-2 w-100">
              <div class="d-flex justify-end">
                <v-rating
                  :model-value="product.rating"
                  color="amber"
                  density="compact"
                  readonly
                  hover
                  size="small"
                  class="px-3"

                ></v-rating>
              </div>
           
              <h4 class="pa-2 pb-0">{{ product.name }}</h4>
              <!-- <v-card-subtitle v-html="product.description"></v-card-subtitle> -->
              <v-card-text  class="py-1 px-2" v-if="product.discounted_price">
                  <p v-if="product.base_price" class="text-decoration-line-through text-red ">
                  {{
                  Math.trunc(product.base_price)
                }}
                {{ $t('rs') }}

                </p>
                <p >
                  {{
                  Math.trunc(product.discounted_price)
                }}
                {{ $t('rs') }}

                </p>
               </v-card-text>
               <v-card-text  class="py-1 px-2" v-else>
                  <p >
                  {{
                  Math.trunc(product.base_price)
                }}
                {{ $t('rs') }}

                </p>
                
               </v-card-text>
                  <!-- <v-card-text  class="" v-if="product.discounted_price">
                  <p v-if="product.base_price" class="text-decoration-line-through text-red ">
                  {{
                  Math.trunc(product.base_price)
                }}
                {{ $t('rs') }}

                </p>
                <p >
                  {{
                  Math.trunc(product.discounted_price)
                }}
                {{ $t('rs') }}

                </p>
               </v-card-text>
               <v-card-text  class="" v-else>
                  <p >
                  {{
                  Math.trunc(product.base_price)
                }}
                {{ $t('rs') }}

                </p>
                
               </v-card-text> -->
              <!-- Add more product details here -->
            </v-card>
          </v-col>
        </v-row>
      </v-window-item>
      <div
        class="position-absolute w-100 d-flex justify-space-between"
        style="top: 40%"
      >
        <v-btn icon @click="next" class="right_btn">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
        <v-btn icon @click="prev" class="left_btn">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </div>
    </v-window>

    <!-- <v-carousel hide-delimiters class="mt-4" height="auto">
        <v-carousel-item v-for="(item,i) in card" :key="i">
          <v-row>
        
              <v-card  class="mx-auto pb-4 mb-2"  max-width="230"  v-for="(card,i) in item" :key="i">
                <template v-slot:loader="{ isActive }">
                  <v-progress-linear
                    :active="isActive"
                    color="deep-purple"
                    height="4"
                    indeterminate
                  ></v-progress-linear>
                </template>
                <v-img width="95%" height="250" :src="card.img"></v-img>
                <v-rating
                  :model-value="card.rate"
                  color="amber"
                  density="compact"
                  half-increments
                  readonly
                  size="small"
                  class="flex-row-reverse px-3"
                  style="width: 100%"
                ></v-rating>
                <v-card-item class="pt-0">
                  <v-card-title> {{ card.title }}</v-card-title>
  
          
                  <span class="me-1 text-grey-lighten-1 font-weight-light d-block">
                    {{ card.description }}
                  </span>
                  <span class="text-decoration-line-through text-red-darken-1">
                    {{ card.lastprice }}
                  </span>
                  <h4>
                    {{ card.price }}
                  </h4>
                </v-card-item>
              </v-card>
          </v-row>
        </v-carousel-item>
      </v-carousel> -->
  </section>
</template>
<script>
import CrudDataService from "../../Service/CrudDataService";
export default {
  data() {
    return {
      window: 0,
      products: [],
    };
  },
  computed: {
    // Group the products into sub-arrays of 3
    groupedProducts() {
      const groups = [];
      for (let i = 0; i < this.products.length; i += 4) {
        groups.push(this.products.slice(i, i + 4));
      }
      return groups;
    },
  },
  methods: {
    next() {
      this.window++;
      if (this.window >= this.groupedProducts.length) {
        this.window = 0; // Wrap around to the first window
      }
    },
    prev() {
      this.window--;
      if (this.window < 0) {
        this.window = this.groupedProducts.length - 1; // Wrap around to the last window
      }
    },
    detail(id) {
      this.$router.push({ name: "Detail", params: { id } });
    },
    async allproduct() {
      let res = await CrudDataService.getAll("products");
      this.products = res.data.data.data;
    },
  },
  mounted() {
    this.allproduct();
  },
};
</script>
<style>
.package .v-btn--icon.v-btn--density-default.right_btn {
  right: -20px;
  background: #dddada !important;
  color: #fff;
}
.package .v-btn--icon.v-btn--density-default.left_btn {
  left: -20px;
  background: #dddada !important;
  color: #fff;
}
</style>
