<template>
  <section class="d-flex flex-column align-center justify-center rounded text-white popup" style="background: #bb86fc; width: 600px; text-align: center;height: 450px; padding: 24px; ">
    <img :src="CheckCircle" class="checkit mb-4" />
    <p style="font-size: 22px;">
     {{ $t('request_will_be_reviewed') }}
      </p>
  </section>
</template>
<script>
import CheckCircle from "../../assets/image/CheckCircle.png";
export default {
  data() {
    return {
      CheckCircle,
    };
  },
};
</script>
<style>
.checkit{
    width: 100px;
}
@media (max-width: 768px) {
  .popup{
width: 100% !important;}
}
</style>